import * as React from "react"
import i18next from '../../i18n.js'

import Layout from "../layout"
import TrainingGrid from "../trainingGrid"

import * as Styles from "../../styles/training.module.scss"
 
// markup
const TrainingPageTemplate = ({  data, pageContext, locale }) => {
  i18next.changeLanguage(locale)
  var  {t} = i18next
 
  var translationContext = { locale:      locale,
                             defaultMenu: true };

  return ( 
    <Layout title={t('trainingPageTitle')}
            pageContext={pageContext}
            hideTitle={true} 
            translationContext={translationContext}>
            
      <div class={Styles.page}>
        <h1>{ t('trainingPageTitle')}</h1>
      <h3>{t('trainingPageSubTitle')}</h3>
      <p>{t('trainingPageIntroduction')}</p>
      
      <TrainingGrid data={data} 
                    locale={locale} />
      </div>         
      

      </Layout>
  )
}

export default TrainingPageTemplate

